import {
  unstable_data as data,
  unstable_defineLoader as defineLoader,
} from '@remix-run/node'
import clsx from 'clsx'
import { useContext } from 'react'
import { useLocales } from 'remix-utils/locales/react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Link, useLoaderData, useRouteLoaderData } from '@remix-run/react'

import { UseBuyOnLoungePair } from '~/components/Mark'
import { getLoungesDirectoryCached } from '~/models/lounges.server'

import { SearchPaletteContext } from '~/contexts/SearchContext'
import {
  type SupportedCurrencies,
  getCurrencyConversions,
} from '~/models/currencies.server'
import { accountSession } from '~/sessions.server'
import { getServerTiming, headersWithTiming } from '~/timing.server'
import { httpsInProduction } from '~/utils/utils.server'

export const headers = headersWithTiming(({ loaderHeaders }) => ({
  Link: loaderHeaders.get('Link')!,
  Vary: 'Cookie',
  'Cache-Control':
    'public, max-age=300, s-maxage=3600, stale-while-revalidate=86400, stale-if-error=86400',
}))

export const handle = {
  features: { footer: false },
  getSitemapEntries: () => {
    return {
      route: '/directory/',
      priority: 0.7,
    }
  },
}

interface MinimalAirport {
  iata: string
  name: string
  lounges: {
    slug: string
    name: string
    location: string | null
    hasLoungePair: boolean
    lowestOffer?: {
      price: number | null
      currency: string
    }
  }[]
}

export const loader = defineLoader(async (args) => {
  const { time, getServerTimingHeader } = getServerTiming()
  const account = await accountSession.getSession(
    args.request.headers.get('Cookie'),
  )
  const hasCurrency =
    account.has('user') && typeof account.get('user')?.currency === 'string'

  const airports = await time(
    'getLoungesDirectoryCached_index',
    getLoungesDirectoryCached(args).then((r) =>
      r.airports.map(
        (a) =>
          ({
            iata: a.iata,
            name: a.name,
            lounges: a.lounges.map((l) => ({
              slug: l.slug,
              name: l.name,
              location: l.location,
              hasLoungePair: l.hasLoungePair,
              lowestOffer: l.lowestOffer
                ? {
                    price: l.lowestOffer.price,
                    currency: l.lowestOffer.currency,
                  }
                : undefined,
            })),
          }) satisfies MinimalAirport,
      ),
    ),
  )

  if (hasCurrency) {
    const accountCurrency = account.get('user')?.currency as SupportedCurrencies
    const [USD, EUR] = await Promise.all([
      getCurrencyConversions('USD'),
      getCurrencyConversions('EUR'),
    ])

    for (const airport of airports) {
      for (const lounge of airport.lounges) {
        if (lounge.lowestOffer?.price) {
          switch (lounge.lowestOffer.currency) {
            case 'USD': {
              const rate = USD.get(accountCurrency)
              if (rate) {
                lounge.lowestOffer.currency = accountCurrency
                lounge.lowestOffer.price = Math.ceil(
                  lounge.lowestOffer.price * rate,
                )
              }

              continue
            }
            case 'EUR': {
              const rate = EUR.get(accountCurrency)
              if (rate) {
                lounge.lowestOffer.currency = accountCurrency
                lounge.lowestOffer.price = Math.ceil(
                  lounge.lowestOffer.price * rate,
                )
              }
              continue
            }
          }
        }
      }
    }
  }

  const canonical = httpsInProduction(new URL(args.request.url))
  canonical.pathname = '/directory/'
  canonical.search = ''

  return data(
    { airports },
    {
      headers: getServerTimingHeader({
        Link: `<${canonical}>; rel="canonical"`,
      }),
    },
  )
})

export default function Home() {
  const { setIsOpen } = useContext(SearchPaletteContext)
  const { airports } = useLoaderData<typeof loader>()

  return (
    <div className="max-w-3xl mx-auto">
      <nav aria-label="Directory" className="pb-20 directory">
        {airports.map((airport) => (
          <AirportWithLounges key={airport.iata} airport={airport} />
        ))}
      </nav>

      <div className="fixed bottom-0 left-0 right-0 z-10 bg-white border-t border-stone-300">
        <div className="max-w-3xl mx-auto px-4">
          <div className="py-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <button
              type="button"
              className="relative w-full"
              onClick={() => setIsOpen(true)}
            >
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon
                  className="w-5 h-5 text-stone-400"
                  aria-hidden="true"
                />
              </div>
              <div className="block w-full py-2 pl-10 pr-3 font-mono text-left no-underline bg-white border rounded-md placeholder-stone-500 border-stone-300 focus:border-blue-500 focus:text-stone-900 focus:placeholder-stone-400 focus:outline-none focus:ring-1 focus:ring-blue-500">
                Search for a lounge
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function AirportWithLounges({
  airport,
}: React.PropsWithoutRef<{
  airport: MinimalAirport
}>) {
  const locales = useLocales()
  return (
    <div>
      {airport.iata ? (
        <Link
          prefetch="intent"
          to={`/at/${airport.iata}/`}
          state={{ from: 'routes/directory/_index/_directory' }}
          className="link"
        >
          <h3>
            {airport.name}
            <span className="iata">({airport.iata})</span>
          </h3>
        </Link>
      ) : null}
      <ul className="lounges">
        {airport.lounges?.map((lounge) => (
          <li key={lounge.slug}>
            <Link
              prefetch="intent"
              to={`/at/${airport.iata}/${encodeURIComponent(lounge.slug)}/`}
              state={{ from: 'routes/directory/_index/_directory' }}
              className="focus:outline-none"
            >
              {lounge.hasLoungePair ? <UseBuyOnLoungePair /> : null}
              <span className="name">{lounge.name}</span>

              {lounge.lowestOffer?.currency && (
                <span
                  className={clsx(lounge.hasLoungePair ? 'offer lp' : 'offer')}
                >
                  {Intl.NumberFormat(locales, {
                    currency: lounge.lowestOffer.currency,
                    style: 'currency',
                  }).format(lounge.lowestOffer?.price ?? 0)}
                </span>
              )}

              {lounge.location && (
                <span className="location">{lounge.location}</span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
